@font-face {
    font-family: 'inter';
    src: url('./Inter-VariableFont_slnt\,wght.ttf');
}

@font-face {
    font-family: 'geist';
    src: url('./GeistVF.ttf'),
         url('./GeistVF.woff'),
         url('./GeistVF.woff2');
}