/* src/index.css */
@import url('./font/font.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9fb !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sider_custom {
  width: 242px !important;
  background-color: #f9f9fb !important;
}

.content_main {
  padding: 13px;
  background-color: #f9f9fb;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
}

.content_box {
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #e8e8ec;
  overflow: auto;
}

.content_box_no_top_padding {
  padding: 0 30px;
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #e8e8ec;
  overflow: auto;
}

.mtb8 {
  margin-bottom: 8px;
}

.category_title {
  font-size: 14px;
  font-weight: 500;
  color: #1f2937;
}

.page_title {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  color: #1c2024;
  font-family: 'geist';
}

.ant-breadcrumb {
  font-family: 'geist';
}

.ant-menu {
  font-family: 'geist';
}

.page_des {
  font-size: 12px;
  color: #60646c;
  margin-bottom: 30px;
  font-family: 'inter';
}

.component_main_box {
  position: relative;
}

.absolute_primary_btn {
  position: absolute;
  top: -70px;
  right: 10px;
  background-color: #5a31f0;
}

.absolute_primary_btn:hover {
  background-color: #4f15de !important;
}

.table_list {
  margin-top: 10px;
  border-radius: 10px;
  border-right: 1px solid #f0f0f0;
}

.table_list .ant-table-cell {
  font-size: 14px;
  line-height: 16px;
  padding: 12px 10px !important;
  text-align: center;
  background-color: #fff !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.table_list th {
  color: #6b7280 !important;
}

.table_list td {
  color: #1c2024 !important;
}

.table_list .actions-box {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ant-progress .ant-progress-bg {
  border-radius: 8px !important;
}

.ant-progress .ant-progress-inner {
  border-radius: 8px !important;
}

.ant-dropdown-menu-item-danger:hover {
  background-color: #fff7f5 !important;
  color: #f05331 !important;
}

:where(
    .css-dev-only-do-not-override-17seli4
  ).ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input)
  .ant-select-selector,
:where(
    .css-dev-only-do-not-override-17seli4
  ).ant-input-outlined.ant-input-disabled,
:where(.css-dev-only-do-not-override-17seli4).ant-input-outlined[disabled] {
  background: #f9f9fb !important;
}

/* https://stackoverflow.com/questions/7492062/css-overflow-scroll-always-show-vertical-scroll-bar */

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.ant-pagination-item-active,
.ant-pagination-item-active > a {
  color: #1c2024 !important;
  border-width: 0 !important;
  background-color: #f0f0f3;
  border-radius: 8px;
}

.ant-pagination-item:hover {
  background-color: #f0f0f3 !important;
}

.ant-pagination-options .ant-select-selector:hover {
  border-color: #5a31f0 !important;
}

.ant-pagination-options .ant-select-item-option-selected {
  background-color: #f9f9fb !important;
}

.logOut {
  position: absolute;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #4f15de;
  cursor: pointer;
  margin-left: 26px;
}

.logOutImg {
  margin-right: 8px;
}

.batch-success-message .ant-message-notice-content{
  background: #F5FBF5!important;
  height: 48px;
  border: 1px solid #E8F7E8!important;
  display: flex!important;
  color: #287F2E!important;
  padding: 9px 20px!important;
  box-shadow: none!important;
}
.batch-success-message .ant-message-notice-content [role="img"]{
  display: none!important;
}
